import React from "react"
import { Link, graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import { HeaderStyle } from "../../components/styles/main"

const IconWrapper = styled.div`
  width: 20%;
  margin-top: 2rem;
`
export const TitleHeader = styled.h2`
  border-bottom: 1px solid #406377;
  width: 80%;
  margin: 3rem 2rem;
`
const ServicesWrapper = styled.div`
  padding: 1rem 2rem;
`

const ServicesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Services" />
      <ServicesWrapper>
        <HeaderStyle>Human Resource Potential Center Services</HeaderStyle>
        {data.allMarkdownRemark.edges.map((element, index) => (
          <Row key={index} style={{ padding: `2rem 3rem` }}>
            <Col>
              <h2>{element.node.frontmatter.title}</h2>
              <IconWrapper>
                <Image
                  path={element.node.frontmatter.icon}
                  alt={element.node.frontmatter.title}
                />
              </IconWrapper>
            </Col>
            <Col>
              <p>{element.node.frontmatter.slug}</p>
              <Link to={element.node.frontmatter.path}>
                <Button style={{ float: `right` }} variant="secondary">
                  More Info
                </Button>
              </Link>
            </Col>
          </Row>
        ))}
      </ServicesWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "service" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            tagline
            title
            path
            icon
          }
        }
      }
    }
  }
`

export default ServicesPage
